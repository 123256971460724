<template>
	<div class="grid">
		<div class="col-12 lg:col-12">
			<div class="border-round border-1 surface-border">
				<iframe style="border: 1px solid #000;" width="100%" height="780"
					:src="`${this.emburl}`" scrolling="yes" seamless="seamless"
					frameBorder="0" allowFullScreen></iframe>
			</div>
		</div>
	</div>
</template>

<script>
import { adminAppUrl } from '../../config/config';
import apiStore from "../store/index"
export default {
	data() {
		return {
			value: 0
		}
	},
	interval: null,
	methods: {
	},
	mounted() {
	},
	computed: {
		emburl() {
			var selectedStore = apiStore.getters['storeinternal/getSelectedStore']
			return adminAppUrl + '?token=' + selectedStore.identifier
		},
	},
	beforeUnmount() {
	}
}
</script>